import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rogauracore GUI is a simple GUI for `}<a parentName="p" {...{
        "href": "https://github.com/wroberts"
      }}>{`wroberts`}</a>{`'s `}<a parentName="p" {...{
        "href": "https://github.com/wroberts/rogauracore"
      }}>{`rogauracore`}</a>{` on Linux. It functions via a taskbar menu using Electron. It requires rogauracore to be installed and in your PATH. Download the `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/rogauracore-gui/releases/latest"
      }}>{`latest release`}</a>{` from `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/rogauracore-gui"
      }}>{`the Github`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/rogauracore-gui.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      